<template>
  <div class="container d-flex flex-column">
    <page-header />

    <div class="flex-1-0">
      <slot></slot>
    </div>
    <page-footer v-if="show"></page-footer>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const { name } = useDisplay();
const { $Utils } = useNuxtApp();
const route = useRoute();

const show = ref(false);

setTimeout(() => {
  const deviceType = $Utils.browserRedirectByPixel(name.value);
  if (route.path.includes("product") && !deviceType) {
    show.value = false;
  } else {
    show.value = true;
  }
}, 200);
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
</style>
